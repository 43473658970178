import React from "react";
import { Link } from "react-router-dom";
import bg5 from "../../assets/images/background/bg-5.png";
import bg6 from "../../assets/images/background/bg-6.png";
import wc from "../../assets/images/resource/wc.png";
import dv from "../../assets/images/resource/dv.jpeg";
import lv from "../../assets/images/resource/lv.jpeg";
import {
  phoneNo,
  googleIframe,
  opentableUrl,
  city,
  address,
  addressLine,
  addressState,
  email,
  timings,
} from "../../constants";

function Book() {
  return (
    <>
      <section className="online-reservation inner-page">
        <div className="left-bg">
          <img src={bg5} alt="" title="" />
        </div>
        <div className="right-bg">
          <img src={bg6} alt="" title="" />
        </div>
        <div className="auto-container">
          <div className="title-box centered">
            <div className="subtitle">
              <span>Reservation</span>
            </div>
            <div className="pattern-image">
              <img
                src={require("../../assets/images/icons/separator.svg").default}
                alt=""
                title=""
              />
            </div>

            <h2>Book A Table</h2>
            <div className="text desc">
              All booking payment is secured with credit card, no charges will
              be aplly for online booking. no refundable.
            </div>
            <div className="text request-info">
              Booking request <Link to="#">{phoneNo}</Link> or fill out the
              order form
            </div>
          </div>
          <div className="default-form reservation-form text-center">
            <a
              className="theme-btn btn-style-one clearfix text-center"
              href={opentableUrl}
            >
              <span className="btn-wrap">
                <span className="text-one">book a table</span>
                <span className="text-two">book a table</span>
              </span>
            </a>
            <div className="powered-by">*Powered by OpenTable</div>
          </div>
        </div>

        <div className="map-location-section">
          <div className="auto-container">
            <div className="outer-box">
              <div className="row clearfix">
                <div className="reserv-col col-lg-8 col-md-12 col-sm-12">
                  <div className="inner">
                    <iframe
                      src={googleIframe}
                      width="100%"
                      height="600"
                      style={{ border: 0 }}
                      allowFullScreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
                <div className="info-col col-lg-4 col-md-12 col-sm-12">
                  <div className="inner">
                    <div className="title">
                      <h2>The Essence</h2>
                      <h4>{city}</h4>
                    </div>
                    <div className="data">
                      <ul className="info">
                        <li>
                          <strong>Contact Us</strong>
                          <br />
                          {addressLine}
                          <br /> {addressState} <br /> Call : {phoneNo} <br />{" "}
                          Email : {email}{" "}
                        </li>
                        <div className="separator">
                          <span></span>
                        </div>
                        {timings.map((item, idx) => (
                          <li key={`key-${idx}`}>
                            {item.title && <strong>{item.title}</strong>}
                            {item.line1 && <div>{item.line1}</div>}
                            {item.line2 && <div>{item.line2}</div>}
                            {item.line3 && <div>{item.line3}</div>}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="location-center">
          <div className="auto-container">
            <div className="row clearfix">
              <div className="location-block col-xl-4 col-lg-6 col-md-6 col-sm-12">
                <div
                  className="inner-box wow fadeInUp"
                  data-wow-duration="1500ms"
                  data-wow-delay="300ms"
                >
                  <div className="icon-box">
                    <img src={wc} alt="" />
                  </div>
                  <h4>Walnut Creek</h4>
                  <div className="text">
                    1512 N Main Street, Walnut Creek, CA <br /> +1 (925)
                    272-7595
                    <br />
                    escwalnutcreek@gmail.com
                  </div>
                  <div className="more-link">
                    <Link to="#">get direction</Link>
                  </div>
                </div>
              </div>

              <div className="location-block col-xl-4 col-lg-6 col-md-6 col-sm-12">
                <div
                  className="inner-box wow fadeInUp"
                  data-wow-duration="1500ms"
                  data-wow-delay="300ms"
                >
                  <div className="icon-box">
                    <img src={lv} alt="" />
                  </div>
                  <h4>Livermore</h4>
                  <div className="text">
                    1650 N Vasco Road, Livermore, CA <br />
                    {phoneNo}
                    <br />
                    escwalnutcreek@gmail.com
                  </div>
                  <div className="more-link">
                    <Link to="#">get direction</Link>
                  </div>
                </div>
              </div>

              <div className="location-block col-xl-4 col-lg-6 col-md-6 col-sm-12">
                <div
                  className="inner-box wow fadeInUp"
                  data-wow-duration="1500ms"
                  data-wow-delay="600ms"
                >
                  <div className="icon-box">
                    <img src={dv} alt="" />
                  </div>
                  <h4>Danville</h4>
                  <div className="text">
                    710 Camino Ramon Danville CA
                    <br />
                    {phoneNo} <br /> escwalnutcreek@gmail.com
                  </div>
                  <div className="more-link">
                    <Link to="#">get direction</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Book;
