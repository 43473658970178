import React from "react";
import { Link } from "react-router-dom";
import BackgroundimageLv from "../../assets/images/background-lv/image-4.jpg";
import BackgroundimageWc from "../../assets/images/background/image-4.jpg";
import logonew from "../../assets/images/logo.png";
import { isLv, phoneLink, phoneNo, address, email } from "../../constants";
import {
  facebook,
  instagram,
  twitter,
  youtube,
  googleMap,
} from "../../constants";

const Backgroundimage = isLv ? BackgroundimageLv : BackgroundimageWc;

function Main() {
  return (
    <>
      <footer className="main-footer">
        <div
          className="image-layer"
          style={{ backgroundImage: `url(${Backgroundimage})` }}
        >
          {" "}
        </div>
        <div className="upper-section">
          <div className="auto-container">
            <div className="row clearfix">
              <div className="footer-col info-col col-lg-6 col-md-12 col-sm-12">
                <div
                  className="inner wow fadeInUp"
                  data-wow-delay="0ms"
                  data-wow-duration="1500ms"
                >
                  <div className="content">
                    <div className="logo">
                      <Link to="/" title="Essence - Restaurants HTML Template">
                        <img
                          src={logonew}
                          alt=""
                          title="Essence - Restaurants HTML Template"
                        />
                      </Link>
                    </div>
                    <div className="info">
                      <ul>
                        <li>{address}</li>
                        <li>
                          <Link to={`mailto:${email}`}>{email}</Link>
                        </li>
                        <li>
                          <Link to={phoneLink}>
                            Booking Request : {phoneNo}
                          </Link>
                        </li>
                        {/*<li>Open : 05:00 pm - 10:00 pm</li>*/}
                      </ul>
                    </div>
                    <div className="separator">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                    {/*<div className="newsletter">
                                            <h3>Get News & Offers</h3>
                                            <div className="text">Subscribe us & Get <span>25% Off.</span></div>
                                            <div className="newsletter-form">
                                                <form method="post" action="/">
                                                    <div className="form-group">
                                                        <span className="alt-icon far fa-envelope"></span>
                                                        <input type="email" name="email" placeholder="Your email" required />
                                                        <button type="submit" className="theme-btn btn-style-one clearfix">
                                                            <span className="btn-wrap">
                                                                <span className="text-one">subscribe</span>
                                                                <span className="text-two">subscribe</span>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>*/}
                  </div>
                </div>
              </div>

              <div className="footer-col links-col col-lg-3 col-md-6 col-sm-12">
                {/*<div className="inner wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <ul className="links">
                                        <li> <Link to="/">Home</Link></li>
                                        <li> <Link to="/menu">Menus</Link></li>
                                        <li> <Link to="/about">About us</Link></li>
                                        <li> <Link to="/ourchefs">Our chefs</Link></li>
                                        <li> <Link to="/contact">Contact</Link></li>
                                    </ul>
                                </div>*/}
              </div>

              <div className="footer-col links-col last col-lg-3 col-md-6 col-sm-12">
                <div
                  className="inner wow fadeInRight"
                  data-wow-delay="0ms"
                  data-wow-duration="1500ms"
                >
                  <ul className="links">
                    <li>
                      {" "}
                      <Link target="_blank" to={facebook}>
                        Facebook
                      </Link>
                    </li>
                    <li>
                      {" "}
                      <Link target="_blank" to={instagram}>
                        Instagram
                      </Link>
                    </li>
                    <li>
                      {" "}
                      <Link target="_blank" to={twitter}>
                        Twitter
                      </Link>
                    </li>
                    <li>
                      {" "}
                      <Link target="_blank" to={youtube}>
                        Youtube
                      </Link>
                    </li>
                    <li>
                      {" "}
                      <Link target="_blank" to={googleMap}>
                        Google map
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="auto-container">
            <div className="copyright">
              &copy; 2023 Asritsolutions LLC. All Rights Reserved | Created by{" "}
              <Link to="https://www.asritsolutions.com/" target="blank">
                Asritsolutions LLC
              </Link>
            </div>
          </div>
        </div>
      </footer>
      <div className="scroll-to-top scroll-to-target" data-target="html">
        <span className="icon fa fa-angle-up"></span>
      </div>
    </>
  );
}

export default Main;
