import React from "react";
import { Link } from "react-router-dom";
import bg3Lv from "../../assets/images/background/bg-3.png";
import badge1Lv from "../../assets/images/resource/badge-1.png";
import img1Lv from "../../assets/images/resource/image-1.jpg";
import img2Lv from "../../assets/images/resource/image-2.jpg";

import bg3Wc from "../../assets/images/background/bg-3.png";
import badge1Wc from "../../assets/images/resource/badge-1.png";
import img1Wc from "../../assets/images/resource/image-1.jpg";
import img2Wc from "../../assets/images/resource/image-2.jpg";

import { isLv, phoneLink, phoneNo } from "../../constants";

const bg3 = isLv ? bg3Lv : bg3Wc;
const badge1 = isLv ? badge1Lv : badge1Wc;
const img1 = isLv ? img1Lv : img1Wc;
const img2 = isLv ? img2Lv : img2Wc;

function Story() {
  return (
    <>
      <section className="story-section">
        <div className="left-bg">
          <img src={bg3} alt="" title="" />
        </div>
        <div className="auto-container">
          <div className="row clearfix">
            <div className="text-col col-xl-5 col-lg-5 col-md-12 col-sm-12">
              <div
                className="inner wow fadeInLeft"
                data-wow-duration="1500ms"
                data-wow-delay="0ms"
              >
                <div className="title-box centered">
                  <div className="subtitle">
                    <span> Our story </span>
                  </div>
                  <div className="pattern-image">
                    <img
                      src={
                        require("../../assets/images/icons/separator.svg")
                          .default
                      }
                      alt="mySvgImage"
                    />
                  </div>
                  <h2>Every Flavor Tells a Story</h2>
                  <div className="text">
                    The Essence provides its guests a feel of traveling across
                    India with all five senses and a plate. A beautiful
                    extension is that the menu undergoes a change every two
                    months with exciting add-ons. Also, whichever region The
                    Essence makes its home, there is an adoption of the local
                    cuisine by its menu.
                  </div>
                </div>
                <div className="booking-info">
                  <div className="bk-title">Book Through Call</div>
                  <div className="bk-no">
                    <Link to={phoneLink}>{phoneNo}</Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="image-col col-xl-7 col-lg-7 col-md-12 col-sm-12">
              <div
                className="inner wow fadeInRight"
                data-wow-duration="1500ms"
                data-wow-delay="0ms"
              >
                <div className="round-stamp">
                  <img src={badge1} alt="" />
                </div>
                <div className="images parallax-scene-1">
                  <div className="image" data-depth="0.15">
                    <img src={img1} alt="" />
                  </div>
                  <div className="image" data-depth="0.30">
                    <img src={img2} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Story;
