import * as cookie from "react-cookie";

import menuimgoneLv from "./assets/images/resource/tandoori-salmon.png";
import menuimgtwoLv from "./assets/images/resource/faluda-fulfi.jpg";
import menuimgthreeLv from "./assets/images/resource/fish-fry.png";
import menuimgfourLv from "./assets/images/resource/ambala-chicken.png";

import menuimgoneWc from "./assets/images/resource/tandoori-salmon.png";
import menuimgtwoWc from "./assets/images/resource/menu-image-12.jpg";
import menuimgthreeWc from "./assets/images/resource/fish-fry.png";
import menuimgfourWc from "./assets/images/resource/ambala-chicken.png";

const cookies = new cookie.Cookies();
const _selLocation = cookies.get("selLocation");

export const isLv = _selLocation === "Livermore";

const menuimgone = isLv ? menuimgoneLv : menuimgoneWc;
const menuimgtwo = isLv ? menuimgtwoLv : menuimgtwoWc;
const menuimgthree = isLv ? menuimgthreeLv : menuimgthreeWc;
const menuimgfour = isLv ? menuimgfourLv : menuimgfourWc;

export const itemsSpecialWc = [
  {
    name: "Tandoori Salmon",
    description:
      "Salmon is marinated with spices and herbs, later baked in a tandoor or oven or air fryer.",
    image: menuimgone,
  },
  {
    name: "Fish Fry",
    description: `A fish fry is a meal containing battered or breaded fried fish`,
    image: menuimgthree,
  },

  {
    name: "Ambala Chicken curry",
    description: `Spiced marinated chicken, caramelized onions, Ginger Garlic and tomatoes.`,
    image: menuimgfour,
  },
  {
    name: "Nalli Rohan Josh",
    description: `Consists of pieces of Lamb shank braised with a gravy flavoured with garlic, ginger and aromatic spices`,
    image: menuimgtwo,
  },
];

export const itemsSpecialLv = [
  {
    name: "Tandoori Salmon",
    description:
      "Salmon is marinated with spices and herbs, later baked in a tandoor or oven or air fryer.",
    image: menuimgone,
  },

  {
    name: "Fish Fry",
    description: `A fish fry is a meal containing battered or breaded fried fish`,
    image: menuimgthree,
  },

  {
    name: "Ambala Chicken curry",
    description: `Spiced marinated chicken, caramelized onions, Ginger Garlic and tomatoes.`,
    image: menuimgfour,
  },
  {
    name: "Falooda Kulfi",
    description: `Delicious chilled dessert that is very popular in North India and is made with layers of kulfi and falooda.`,
    image: menuimgtwo,
  },
];

export const itemsSpecial = isLv ? itemsSpecialLv : itemsSpecialWc;
