import { BrowserRouter as Router } from "react-router-dom";
import Routing from "./routes";
import AppSelect from "./AppSelect";
import { useCookies } from "react-cookie";
import "./assets/css/style.css";
import "./assets/css/responsive.css";

export default function App() {
  const [cookies, setCookie] = useCookies([""]);
  return (
    <>
      {cookies && cookies.selLocation ? (
        <Router>
          <Routing />
        </Router>
      ) : (
        <AppSelect />
      )}
    </>
  );
}
