import React, { useState } from "react";
import { Link } from "react-router-dom";
import bg1Wc from "../../assets/images/background/bg-1.png";
import bg2Wc from "../../assets/images/background/bg-2.png";
import bg1Lv from "../../assets/images/background/bg-1.png";
import bg2Lv from "../../assets/images/background/bg-2.png";

import offerimg1Wc from "../../assets/images/resource/offer-image-1.jpg";
import offerimg2Wc from "../../assets/images/resource/offer-image-2.jpg";
import offerimg3Wc from "../../assets/images/resource/offer-image-3.jpg";

import offerimg1Lv from "../../assets/images/resource/offer-image-1.jpg";
import offerimg2Lv from "../../assets/images/resource/offer-image-2.jpg";
import offerimg3Lv from "../../assets/images/resource/offer-image-3.jpg";

import { isLv } from "../../constants";

const offerimg1 = isLv ? offerimg1Lv : offerimg1Wc;
const offerimg2 = isLv ? offerimg2Lv : offerimg2Wc;
const offerimg3 = isLv ? offerimg3Lv : offerimg3Wc;
const bg1 = isLv ? bg1Lv : bg1Wc;
const bg2 = isLv ? bg2Lv : bg2Wc;

function Offer() {
  const [zoomImg, setZoomImg] = useState(false);
  const offers = [
    {
      image: offerimg1,
      title: "Drinks",
      link: "#",
    },
    {
      image: offerimg2,
      title: "Appetizers",
      link: "#",
    },
    {
      image: offerimg3,
      title: "Dinner",
      link: "#",
    },
  ];

  return (
    <>
      {zoomImg && (
        <div className="zoom-img-container">
          <span
            onClick={() => {
              setZoomImg(false);
            }}
            className="close-btn"
          >
            x
          </span>
          <img src={zoomImg} height={400} width={400} className="zoom-img" />
        </div>
      )}

      <section className="we-offer-section">
        <div className="left-bot-bg">
          <img src={bg1} alt="" title="" />
        </div>
        <div className="right-top-bg">
          <img src={bg2} alt="" title="" />
        </div>
        <div className="auto-container">
          <div className="title-box centered">
            <div className="subtitle">
              <span>Flavors for royalty</span>
            </div>
            <div className="pattern-image">
              <img
                src={require("../../assets/images/icons/separator.svg").default}
                alt="mySvgImage"
              />
            </div>
            <h2>Dining experience</h2>
            <div className="text">
              At The Essence, we provide high quality dining experience with a
              variety of dishes specially designed from India.
            </div>
          </div>
          <div className="row justify-content-center clearfix">
            {offers.map((offer, index) => (
              <div
                key={index}
                className="offer-block col-xl-4 col-lg-4 col-md-6 col-sm-12"
              >
                <div
                  className="inner-box wow fadeInUp"
                  data-wow-duration="1500ms"
                  data-wow-delay={`${index * 300}ms`}
                >
                  <div
                    className="image"
                    onClick={() => {
                      setZoomImg(offer.image);
                    }}
                  >
                    <Link to="#">
                      <img src={offer.image} alt="" />
                    </Link>
                  </div>
                  <h3>
                    <Link to="#">{offer.title}</Link>
                  </h3>
                  {/*<div className="more-link"><Link to="#">view menu</Link></div>*/}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Offer;
