import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { isLv, phoneNo } from "../../constants";
import { itemsSpecial } from "../../constants2";

function Special() {
  const [zoomImg, setZoomImg] = useState(false);

  const options = {
    slidesPerView: 4,
    spaceBetween: 45,
    loop: true,
    navigation: {
      prevEl: ".swiper-button-prev",
      nextEl: ".swiper-button-next",
    },
    autoplay: {
      delay: 7000,
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 15,
      },
      600: {
        slidesPerView: 1,
        spaceBetween: 15,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      992: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 4,
      },
    },
  };

  return (
    <>
      {zoomImg && (
        <div className="zoom-img-container">
          <span
            onClick={() => {
              setZoomImg(false);
            }}
            className="close-btn"
          >
            x
          </span>
          <img src={zoomImg} height={400} width={400} className="zoom-img" />
        </div>
      )}
      <section className="special-offer">
        <div className="outer-container">
          <div className="auto-container">
            <div className="title-box centered">
              <div className="subtitle">
                <span>special offer</span>
              </div>
              <div className="pattern-image">
                <img
                  src={
                    require("../../assets/images/icons/separator.svg").default
                  }
                  alt="mySvgImage"
                />
              </div>
              <h2>Best Specialties</h2>
            </div>
            <Swiper className="dish-gallery-slider " {...options}>
              {itemsSpecial.map((item) => (
                <SwiperSlide key={item.name} className="offer-block-two">
                  <div className="inner-box">
                    <div
                      className="image"
                      onClick={() => {
                        setZoomImg(item.image);
                      }}
                    >
                      <Link to="/">
                        <img src={item.image} alt="" />
                      </Link>
                    </div>
                    <h4
                      onClick={() => {
                        setZoomImg(item.image);
                      }}
                    >
                      <Link to="#">{item.name} </Link>
                    </h4>
                    <div className="text desc">{item.description}</div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
}

export default Special;
