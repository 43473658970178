import React from "react";

import bg8Lv from "../../assets/images/background/bg-8.png";
import bg7Lv from "../../assets/images/background/bg-7.png";
import whyicononeLv from "../../assets/images/resource/why-icon-1.png";
import whyicontwoLv from "../../assets/images/resource/why-icon-2.png";
import whyiconthreeLv from "../../assets/images/resource/why-icon-3.png";
import whyiconfourLv from "../../assets/images/resource/why-icon-4.png";

import bg8Wc from "../../assets/images/background/bg-8.png";
import bg7Wc from "../../assets/images/background/bg-7.png";
import whyicononeWc from "../../assets/images/resource/why-icon-1.png";
import whyicontwoWc from "../../assets/images/resource/why-icon-2.png";
import whyiconthreeWc from "../../assets/images/resource/why-icon-3.png";
import whyiconfourWc from "../../assets/images/resource/why-icon-4.png";
import { isLv } from "../../constants";

const bg8 = isLv ? bg8Lv : bg8Wc;
const bg7 = isLv ? bg7Lv : bg7Wc;
const whyiconone = isLv ? whyicononeLv : whyicononeWc;
const whyicontwo = isLv ? whyicontwoLv : whyicontwoWc;
const whyiconthree = isLv ? whyiconthreeLv : whyiconthreeWc;
const whyiconfour = isLv ? whyiconfourLv : whyiconfourWc;

function Whyus() {
  const whyData = [
    {
      icon: whyiconone,
      title: "Hygienic Food",
      text: "Our staff is fully trained to provide you hygienic Food.",
    },
    {
      icon: whyicontwo,
      title: "Fresh Environment",
      text: "We provide high quality family dining ambiance.",
    },
    {
      icon: whyiconthree,
      title: "Skilled Chefs",
      text: "Our chefs are fully trained and experience in Indian food.",
    },
    {
      icon: whyiconfour,
      title: "Event & Party",
      text:
        "Host you next event or party with us. We do provide full service catering",
    },
  ];

  return (
    <>
      <section className="why-us">
        <div className="left-bg">
          <img src={bg8} alt="" title="" />
        </div>
        <div className="right-bg">
          <img src={bg7} alt="" title="" />
        </div>
        <div className="auto-container">
          <div className="title-box centered">
            <div className="subtitle">
              <span>why choose us</span>
            </div>
            <div className="pattern-image">
              <img
                src={require("../../assets/images/icons/separator.svg").default}
                alt="mySvgImage"
              />
            </div>

            <h2>Our Strength</h2>
          </div>
          <div className="row clearfix">
            {whyData.map((data, index) => (
              <div
                key={index}
                className="why-block col-xl-3 col-lg-6 col-md-6 col-sm-12"
              >
                <div
                  className="inner-box wow fadeInUp"
                  data-wow-duration="1500ms"
                  data-wow-delay={`${index * 300}ms`}
                >
                  <div className="icon-box">
                    <img src={data.icon} alt="" />
                  </div>
                  <h4>{data.title}</h4>
                  <div className="text">{data.text}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Whyus;
