import * as cookie from "react-cookie";

const cookies = new cookie.Cookies();
const _selLocation = cookies.get("selLocation");

export const isLv = _selLocation === "Livermore";
export const addressLivermore = "1650 N Vasco Road Livermore, CA 94551";
export const addressWalnutcreek = "1512 N Main Street, Walnut Creek, CA 94596";

export const addressLineLv = "1650 N Vasco Road ";
export const addressStateLv = "Livermore, CA 94551";
export const addressLineWc = "1512 N Main Street";
export const addressStateWc = "Walnut Creek, CA 94596";
export const cityLv = "Livermore";
export const cityWc = "Walnut Creek";

export const phoneNoLv = "+1 (925) 718-7555";
export const phoneNoWc = "+1 (925) 718-7555";
// export const phoneNoLv = "+1 (925) 783-2425";
// export const phoneNoWc = "+1 (925) 783-2425";

// export const telLv = "tel:19257832425";
// export const telWc = "tel:19257832425";
export const telLv = "tel:19257187555";
export const telWc = "tel:19257187555";

export const emailLv = "esclivermore@gmail.com";
export const emailWc = "escwalnutcreek@gmail.com";

export const lunchLv = "11.30 am to 2.30 pm";
export const lunchWeLv = "12.00 am to 3.00 pm";
export const dinnerLv = "5.00 pm to 10.00 pm";

export const lunchWc = "11.30 am to 2.30 pm";
export const lunchWeWc = "12.00 am to 3.00 pm";
export const dinnerWc = "5.00 pm to 10.00 pm";

export const mondayLv = "Monday closed";
export const mondayWc = "Monday closed";

export const timingsLv = [
  {title: "Lunch Time", line1:"Monday to Sunday", line2: "11.30 am - 2.30pm", line3:""},
  {title: "", line1:"Saturday & Sunday", line2: "12.00 pm - 3.00pm", line3:""},
  {title: "Dinner Time", line1:"05.00 pm - 10.00pm", line2: "", line3:""},
];

export const timingsWc = [
  {title: "Lunch Time", line1:"Tuesday to Sunday", line2: "11.30 am - 2.30pm", line3:""},
  {title: "", line1:"Saturday & Sunday", line2: "12.00 pm - 3.00pm", line3:""},
  {title: "Dinner Time", line1:"05.00 pm - 10.00pm", line2: "", line3:""},
  {title: "Monday Closed", line1:"", line2: "", line3:""},
];


export const emailSubLv = "The Essence Livermore";
export const emailSubWc = "The Essence Walnut Creek";


export const facebookLv =
  "https://www.facebook.com/profile.php?id=61551901346062";
export const facebookWc =
  "https://www.facebook.com/profile.php?id=61551901346062";

export const instagramLv = "https://www.instagram.com/the_essence_cuisine";
export const instagramWc = "https://www.instagram.com/the_essence_cuisine";

export const twitterLv = "https://twitter.com";
export const twitterWc = "https://twitter.com";

export const youtubeLv = "https://www.youtube.com";
export const youtubeWc = "https://www.youtube.com";

export const googleMapLv = "https://maps.app.goo.gl/6bSf8WQUBg2WRGe78";
export const googleMapWc = "https://maps.app.goo.gl/6bSf8WQUBg2WRGe78";

export const googleIframeLv = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3156.0223133041045!2d-121.72540500000001!3d37.7191541!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fe6d6a7ca3057%3A0xd3d038bf55d58727!2s1650%20N%20Vasco%20Rd%2C%20Livermore%2C%20CA%2094551!5e0!3m2!1sen!2sus!4v1697265819339!5m2!1sen!2sus"
export const googleIframeWc = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3148.2922022652474!2d-122.06349252426953!3d37.90023227195399!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808561c002e9948d%3A0x1fc8a5894314ed0c!2s1512%20N%20Main%20St%2C%20Walnut%20Creek%2C%20CA%2094596!5e0!3m2!1sen!2sus!4v1696095271954!5m2!1sen!2sus"

export const opentableUrlLv = "https://www.opentable.com/r/the-essence-livermore-reservations-livermore?restref=1322491&lang=en-US&ot_source=Restaurant%20website"
export const opentableUrlWc = "https://www.opentable.com/r/the-essence-reservations-walnut-creek?restref=1329562&lang=en-US&ot_source=Restaurant%20website"

export const orderLinkLv = "https://orderlv.essenceusa.com";
export const orderLinkWc = "https://order.essenceusa.com";

export const timings = isLv ? timingsLv : timingsWc;
export const monday = isLv ? mondayLv : mondayWc;
export const email = isLv ? emailLv : emailWc;
export const phoneLink = isLv ? telLv : telWc;
export const emailSub = isLv ? emailSubLv : emailSubWc;
export const phoneNo = isLv ? phoneNoLv : phoneNoWc;
export const address = isLv ? addressLivermore : addressWalnutcreek;
export const addressLine = isLv ? addressLineLv : addressLineWc;
export const addressState = isLv ? addressStateLv : addressStateWc;
export const city = isLv ? cityLv : cityWc;
export const lunch = isLv ? lunchLv : lunchWc;
export const lunchWe = isLv ? lunchWeLv : lunchWeWc;
export const dinner = isLv ? dinnerLv : dinnerWc;
export const facebook = isLv ? facebookLv : facebookWc;
export const instagram = isLv ? instagramLv : instagramWc;
export const twitter = isLv ? twitterLv : twitterWc;
export const youtube = isLv ? youtubeLv : youtubeWc;
export const googleMap = isLv ? googleMapLv : googleMapWc;
export const googleIframe = isLv ? googleIframeLv : googleIframeWc;
export const opentableUrl = isLv ? opentableUrlLv : opentableUrlWc;
export const orderLink = isLv ? orderLinkLv : orderLinkWc;

export const CONSTANTS = {
  API_KEY: "DINE-IN-IPAD",
  CORE_API_URL: "https://api.theyellowchillidanville.com/core",
  DOORDASH_URL:
    "https://order.online/business/the-yellow-chilli-by-master-chef-sanjeev-kapoor-11158513",
  OPENTABLE_URL:
    "https://www.opentable.com/r/the-yellow-chilli-by-master-chef-sanjeev-kapoor-danville-reservations-san-ramon?restref=1231807&lang=en-US&ot_source=Restaurant%20website",
};
