import React, { useState } from "react";
import logo from "./assets/images/logo.png";
import { addressLivermore, addressWalnutcreek } from "./constants";
import { useCookies } from "react-cookie";

function AppSelect() {
  const [cookies, setCookie] = useCookies([""]);
  const [loading, setLoading] = useState(false);

  function handleLocationChange(_loc) {
    setCookie("selLocation", _loc);
    window.location.reload();
  }

  return (
    <div className="container" style={{ marginTop: 55 }}>
      <div className="row  text-center">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div className="logo-box">
            <img src={logo} alt="" title="Essence - Redefining Indian Food" />
          </div>
        </div>
        <div className="col-md-3 col-sm-12"></div>
        <div className="col-md-6 col-sm-12">
          <div className="row  text-center">
            <div className="col-md-6 col-sm-12 mt-4">
              <button
                onClick={() => handleLocationChange("Livermore")}
                className="select-loc-btn btn-style-one clearfix  text-center"
              >
                <span className="text-one">{"Livermore"}</span>
              </button>
            </div>
            <div className="col-md-6 col-sm-12 mt-4">
              <button
                onClick={() => handleLocationChange("WalnutCreek")}
                className="select-loc-btn btn-style-one clearfix text-center"
              >
                <span className="text-one">{"Walnut Creek"}</span>
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-sm-12 mt-4">
          <h2 className="mt-4">{"Address"}</h2>
          <h6 className="mt-2">{addressLivermore}</h6>
          <h6 className="mt-2">{addressWalnutcreek}</h6>
        </div>
      </div>
    </div>
  );
}

export default AppSelect;
