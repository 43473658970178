import React from "react";
import Contactbanner from "../Mencontactus/Contactbanner";
import Bredcrumb from "../Bredcrumb/Main";
// TODO:
import ImgLv from "../../assets/images/background-lv/banner-image-4.jpg";
import ImgWc from "../../assets/images/background/banner-image-4.jpg";
import { isLv } from "../../constants";

const Img = isLv ? ImgLv : ImgWc;
function Main() {
  return (
    <>
      <Bredcrumb title="ANY QUERY ?" subtitle="Contact Us" Img={Img} />
      <Contactbanner />
    </>
  );
}

export default Main;
