import React from "react";
import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";
import Iframe from "react-iframe";

import bg25Lv from "../../assets/images/background/bg-25.png";
import bg6Lv from "../../assets/images/background/bg-6.png";
import restroLv from "../../assets/images/resource/restaurant.png";

import bg25Wc from "../../assets/images/background/bg-25.png";
import bg6Wc from "../../assets/images/background/bg-6.png";
import restroWc from "../../assets/images/resource/restaurant.png";

import {
  CONSTANTS,
  phoneLink,
  phoneNo,
  email,
  emailSub,
  googleIframe,
  timings
} from "../../constants";
import { isLv, address, lunch, dinner, monday } from "../../constants";

const bg25 = isLv ? bg25Lv : bg25Wc;
const bg6 = isLv ? bg6Lv : bg6Wc;
const restro = isLv ? restroLv : restroWc;

const errorStyles = { color: "red" };
const successStyles = { color: "green" };

function Contactbanner() {
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const [fnError, setfnError] = useState("");
  const [lnError, setlnError] = useState("");
  const [emError, setemError] = useState("");
  const [phError, setphError] = useState("");
  const [mssError, setmssError] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const firstName = event.target.firstName.value;
    const lastName = event.target.firstName.value;
    const email = event.target.email.value;
    const message = event.target.message.value;
    const phone = event.target.phone.value;
    const subject = emailSub;

    if (!firstName) {
      setfnError("Full name is required. ");
    } else {
      setfnError("");
    }

    if (!email) {
      setemError("Email is required. ");
    } else {
      setemError("");
    }

    if (!message) {
      setmssError("Message is required. ");
    } else {
      setmssError("");
    }

    if (!phone) {
      setphError("Phone is required. ");
    } else {
      setphError("");
    }

    if (!firstName || !lastName || !email || !message || !phone) {
      return null;
    }

    try {
      const data = {
        firstName,
        lastName,
        email,
        message,
        phone,
        subject,
        accountId: "1",
        locationId: "1",
        app: "WEBSITE",
      };

      const url = `${CONSTANTS.CORE_API_URL}/us/contact`;
      const response = await axios({
        url: url,
        method: "post",
        data: data,
        headers: {
          "x-api-key": CONSTANTS.API_KEY,
        },
      });

      if (response && response.data && response.data.id) {
        setSuccess(true);
      } else {
        setError(true);
      }
    } catch (e) {
      console.log(e);
      setError(true);
    }
  };

  return (
    <>
      <div className="contact-map">
        <Iframe
          src={googleIframe}
          width="100%"
          height="500"
          style="border:0;"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></Iframe>
      </div>

      <section className="contact-page">
        <div className="left-bg">
          <img src={bg25} alt="" title="" />
        </div>
        <div className="right-bg">
          <img src={bg6} alt="" title="" />
        </div>

        <div className="location-center">
          <div className="auto-container">
            <div className="cinfo-box">
              <div className="row clearfix">
                <div className="contactinfo-block col-lg-4 col-md-4 col-sm-12">
                  <div
                    className="inner-box wow fadeInUp"
                    data-wow-duration="1500ms"
                    data-wow-delay="300ms"
                  >
                  {timings.map((item, idx) => (
                    <li key={`key-${idx}`}>
                      {item.title && <strong>{item.title}</strong>}
                      {item.line1 && <div><small>{item.line1}</small></div>}
                      {item.line2 && <div><small>{item.line2}</small></div>}
                      {item.line3 && <div><small>{item.line3}</small></div>}
                    </li>
                  ))}
                  </div>
                </div>

                <div className="contactinfo-block col-lg-4 col-md-4 col-sm-12">
                  <div
                    className="inner-box cp-seprator wow fadeInUp"
                    data-wow-duration="1500ms"
                    data-wow-delay="300ms"
                  >
                    <h4>Contact Info</h4>
                    <div className="text">
                      {address}
                      <br />
                      Email : {email}
                    </div>
                    <div className="more-link">
                      <Link to={phoneLink}>Booking : {phoneNo}</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="auto-container">
          <div className="c-page-form-box">
            <div className="row clearfix">
              <div className="loc-block col-lg-6 col-md-12 col-sm-12">
                <div className="title-box centered">
                  <h2>Message us</h2>
                  <div className="text desc">
                    Have a question about the our service? We're here to help,
                    contact us today
                  </div>
                </div>
                <div className="default-form reservation-form">
                  <form onSubmit={handleSubmit} data-status="init">
                    <div className="clearfix">
                      <div className="form-group">
                        <div className="field-inner">
                          <input
                            type="text"
                            name="firstName"
                            placeholder="Full Name"
                            required=""
                          />
                        </div>
                        {fnError && <div style={errorStyles}>{fnError}</div>}
                      </div>
                      <div className="form-group">
                        <div className="field-inner">
                          <input
                            type="email"
                            name="email"
                            placeholder="Your Email"
                            required={true}
                          />
                        </div>
                        {emError && <div style={errorStyles}>{emError}</div>}
                      </div>
                      <div className="form-group ">
                        <div className="field-inner">
                          <input
                            type="number"
                            name="phone"
                            placeholder="Phone Number"
                            required=""
                          />
                        </div>
                        {phError && <div style={errorStyles}>{phError}</div>}
                      </div>

                      <div className="form-group ">
                        <div className="field-inner">
                          <textarea
                            maxLength={200}
                            name="message"
                            placeholder="Special Request"
                            required=""
                            rows="1"
                          ></textarea>
                        </div>
                        {mssError && <div style={errorStyles}>{mssError}</div>}
                      </div>
                      {error && (
                        <div style={errorStyles}>
                          {`Something went wrong, please try after sometime.`}
                        </div>
                      )}
                      {success && (
                        <div style={successStyles}>
                          {`Your message is submitted sucessfully, we will contact you soon.`}
                        </div>
                      )}

                      <div className="form-group">
                        <button
                          type="submit"
                          className="theme-btn btn-style-one clearfix"
                        >
                          <span className="btn-wrap">
                            <span className="text-one">send your message</span>
                            <span className="text-two">send your message</span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="loc-block col-lg-6 col-md-12 col-sm-12">
                <img src={restro} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contactbanner;
