import React from "react";
import { Link } from "react-router-dom";

import BackgroundofferLv from "../../assets/images/background/image-1.jpg";
import record3Lv from "../../assets/images/resource/image-3.jpg"; // FIX
import recordbg1Lv from "../../assets/images/background/image-1.jpg";
import recordbg2Lv from "../../assets/images/background-lv/bg-4.png";
import recordbg3Lv from "../../assets/images/resource-lv/badge-2.png";

import BackgroundofferWc from "../../assets/images/background/image-1.jpg";
import record3Wc from "../../assets/images/resource/image-3.jpg";
import recordbg1Wc from "../../assets/images/background/image-1.jpg";
import recordbg2Wc from "../../assets/images/background/bg-4.png";
import recordbg3Wc from "../../assets/images/resource/badge-2.png";

import { isLv } from "../../constants";

const Backgroundoffer = isLv ? BackgroundofferLv : BackgroundofferWc;
const record3 = isLv ? record3Lv : record3Wc;
const recordbg1 = isLv ? recordbg1Lv : recordbg1Wc;
const recordbg2 = isLv ? recordbg2Lv : recordbg2Wc;
const recordbg3 = isLv ? recordbg3Lv : recordbg3Wc;

function Specialdish() {
  return (
    <>
      <section className="special-dish">
        <div className="bottom-image">
          <img src={record3} alt="" title="" />
        </div>
        <div className="outer-container">
          <div className="row clearfix">
            <div className="image-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div
                className="inner wow fadeInLeft"
                data-wow-duration="1500ms"
                data-wow-delay="0ms"
              >
                <div
                  className="image-layer"
                  style={{ backgroundImage: `url(${Backgroundoffer})` }}
                ></div>
                <div className="image">
                  <img src={recordbg1} alt="" />
                </div>
              </div>
            </div>
            <div className="content-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="right-bg">
                <img src={recordbg2} alt="" title="" />
              </div>
              <div
                className="inner wow fadeInRight"
                data-wow-duration="1500ms"
                data-wow-delay="0ms"
              >
                <div className="title-box">
                  <span className="badge-icon">
                    <img src={recordbg3} alt="" title="" />
                  </span>
                  <div className="subtitle">
                    <span>Special dish</span>
                  </div>

                  <div className="pattern-image">
                    <img
                      src={
                        require("../../assets/images/icons/separator.svg")
                          .default
                      }
                      alt="mySvgImage"
                    />
                  </div>

                  <h3>Essence Butter Chicken</h3>
                  <div className="text">
                    Essence Butter Chicken is a classic Indian dish that’s made by
                    simmering marinated & grilled chicken (Tandoori chicken) in
                    a spicy, aromatic, buttery and creamy tomato gravy. This
                    lip-smacking and delicious dish is hugely popular among the
                    Indian food lovers across the world.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Specialdish;
