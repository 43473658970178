import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";


// FIXME:
import BgtwoLv from "../../assets/images/background-lv/image-2.jpg";
import author1Lv from "../../assets/images/resource/author-thumb-1.jpg";
import author2Lv from "../../assets/images/resource/author-thumb-2.jpg";
import author3Lv from "../../assets/images/resource/author-thumb-3.jpg";

import BgtwoWc from "../../assets/images/background/image-2.jpg";
import author1Wc from "../../assets/images/resource/author-thumb-1.jpg";
import author2Wc from "../../assets/images/resource/author-thumb-2.jpg";
import author3Wc from "../../assets/images/resource/author-thumb-3.jpg";

import SwiperCore, { Controller } from "swiper";
import { isLv, phoneLink, phoneNo, opentableUrl, timings, address, timingss } from "../../constants";

const Bgtwo = isLv ? BgtwoLv : BgtwoWc;
const author1 = isLv ? author1Lv : author1Wc;
const author2 = isLv ? author2Lv : author2Wc;
const author3 = isLv ? author3Lv : author3Wc;

SwiperCore.use([Controller]);
function Contact() {
  const swiper1 = useRef(null);
  const swiper2 = useRef(null);

  const [flag, setflag] = useState(false);

  const slider2 = useRef();
  const duration = 500;
  const syncPosition = (e) => {
    if (!flag) {
      setflag(false);
      if (slider2.current) {
        slider2.current.to(e.item.index, duration);
      }
      setflag(false);
    }
  };

  return (
    <>
      <div className="testimonials-section">
        <div
          className="image-layer"
          style={{ backgroundImage: `url(${Bgtwo})` }}
        ></div>
        <div className="auto-container">
          <div className="carousel-box owl-theme">
            <Swiper
              className="testi-top"
              ref={swiper1}
              controller={{ control: swiper2.current }}
              items={1}
              loop
              margin={10}
              autoplay
              onChange={(e) => syncPosition(e)}
            >
              <SwiperSlide className="slide-item">
                <div className="slide-content">
                  <div className="quotes">”</div>
                  <div className="text quote-text">
                    I wanted to thank you for inviting me down for that amazing
                    dinner the other night. The food was extraordinary.
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="slide-item">
                <div className="slide-content">
                  <div className="quotes">”</div>
                  <div className="text quote-text">
                    I wanted to thank you for inviting me down for that amazing
                    dinner the other night. The food was extraordinary.
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="slide-item">
                <div className="slide-content">
                  <div className="quotes">”</div>
                  <div className="text quote-text">
                    I wanted to thank you for inviting me down for that amazing
                    dinner the other night. The food was extraordinary.
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="slide-item">
                <div className="slide-content">
                  <div className="quotes">”</div>
                  <div className="text quote-text">
                    I wanted to thank you for inviting me down for that amazing
                    dinner the other night. The food was extraordinary.
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="slide-item">
                <div className="slide-content">
                  <div className="quotes">”</div>
                  <div className="text quote-text">
                    I wanted to thank you for inviting me down for that amazing
                    dinner the other night. The food was extraordinary.
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="slide-item">
                <div className="slide-content">
                  <div className="quotes">”</div>
                  <div className="text quote-text">
                    I wanted to thank you for inviting me down for that amazing
                    dinner the other night. The food was extraordinary.
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
            <div className="separator">
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div className="thumbs-carousel-box">
              <Swiper className="testi-thumbs" loop={true} autoplay={true}>
                <SwiperSlide className="slide-item">
                  <div className="image">
                    <img src={author1} alt="" />
                  </div>
                  <div className="auth-title">Sam Jhonson</div>
                </SwiperSlide>
                <SwiperSlide className="slide-item">
                  <div className="image">
                    <img src={author2} alt="" />
                  </div>
                  <div className="auth-title">Ian Botham</div>
                </SwiperSlide>
                <SwiperSlide className="slide-item">
                  <div className="image">
                    <img src={author3} alt="" />
                  </div>
                  <div className="auth-title">Dan Bitson</div>
                </SwiperSlide>
                <SwiperSlide className="slide-item">
                  <div className="image">
                    <img src={author1} alt="" />
                  </div>
                  <div className="auth-title">Sam Jhonson</div>
                </SwiperSlide>
                <SwiperSlide className="slide-item">
                  <div className="image">
                    <img src={author2} alt="" />
                  </div>
                  <div className="auth-title">Ian Botham</div>
                </SwiperSlide>
                <SwiperSlide className="slide-item">
                  <div className="image">
                    <img src={author3} alt="" />
                  </div>
                  <div className="auth-title">Dan Bitson</div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>

      <section className="reserve-section">
        <div className="auto-container">
          <div className="outer-box">
            <div className="row clearfix">
              <div className="reserv-col col-lg-8 col-md-12 col-sm-12">
                <div className="inner">
                  <div className="title">
                    <h2>Online Reservation</h2>
                    <div className="request-info">
                      Booking request <Link to={phoneLink}>{phoneNo}</Link> or
                      fill out the order form
                    </div>
                  </div>
                  <div className="default-form reservation-form">
                    <div className="field-inner text-center">
                      <a
                        href={opentableUrl}
                        className="theme-btn btn-style-one clearfix"
                      >
                        <span className="btn-wrap">
                          <span className="text-one">book a table</span>
                          <span className="text-two">book a table</span>
                        </span>
                      </a>
                    </div>
                    <div className="field-inner text-center mt-4">
                      Powered by Opentable
                    </div>
                  </div>
                </div>
              </div>
              <div className="info-col col-lg-4 col-md-12 col-sm-12">
                <div className="inner">
                  <div className="title">
                    <h2>Contact Us</h2>
                  </div>
                  <div className="data">
                    <div className="booking-info">
                      <div className="bk-title">Booking request</div>
                      <div className="bk-no white-space">
                        <Link to={phoneLink}>{phoneNo}</Link>

                        {/* <Link to={phoneLink}>
                          {phoneNo}
                        </Link> */}
                      </div>
                    </div>
                    <div className="separator">
                      <span></span>
                    </div>
                    <ul className="info">
                      <li>
                        <strong>Location</strong>
                        <br />
                        <br />
                        {address}
                      </li>
                      {timings.map((item, idx) => (
                        <li key={`key-${idx}`}>
                          {item.title && <strong>{item.title}</strong>}
                          {item.line1 && <div>{item.line1}</div>}
                          {item.line2 && <div>{item.line2}</div>}
                          {item.line3 && <div>{item.line3}</div>}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;
