import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SlideoneWc from "../../assets/images/main-slider/slider-1.jpg";
import SlidetwoWc from "../../assets/images/main-slider/slider-2.jpg";
import SlidethreeWc from "../../assets/images/main-slider/slider-3.jpg";

// FIXME:
import SlideoneLv from "../../assets/images/main-slider-lv/slider-1.jpg";
import SlidetwoLv from "../../assets/images/main-slider-lv/slider-2.jpg";
import SlidethreeLv from "../../assets/images/main-slider-lv/slider-3.jpg";
import bookicon1Wc from "../../assets/images/resource/book-icon-1.png";
import bookicon1Lv from "../../assets/images/resource/book-icon-1.png";
import { isLv } from "../../constants";

const Slideone = isLv ? SlideoneLv : SlideoneWc;
const Slidetwo = isLv ? SlidetwoLv : SlideoneWc;
const Slidethree = isLv ? SlidethreeLv : SlideoneWc;
const bookicon1 = isLv ? bookicon1Lv : bookicon1Wc;

function Banner() {
  return (
    <Swiper
      className="banner-section"
      loop
      spaceBetween={50}
      slidesPerView={1}
      navigation={{
        nextEl: ".swiper-button-prev",
        prevEl: ".swiper-button-next",
      }}
    >
      <div className="banner-container">
        <div className="banner-slider">
          <div className="swiper-wrapper">
            {[Slideone, Slidetwo, Slidethree].map((image, index) => (
              <SwiperSlide key={index}>
                <div
                  className="swiper-slide slide-item"
                  style={{ backgroundImage: `url(${image})` }}
                >
                  <div className="auto-container">
                    <div className="content-box">
                      <div className="content">
                        <div className="clearfix">
                          <div className="inner">
                            <div className="subtitle">
                              <span>delightful experience</span>
                            </div>
                            <div className="pattern-image">
                              <img
                                src={
                                  require("../../assets/images/icons/separator.svg")
                                    .default
                                }
                                alt="mySvgImage"
                              />
                            </div>
                            <h1>
                              <span>
                                {index === 0
                                  ? "Flavors Inspired by"
                                  : index === 1
                                  ? "Where every flavor"
                                  : "For the love of"}
                                <br />
                                {index === 0
                                  ? "the Seasons"
                                  : index === 1
                                  ? "tells a story"
                                  : "Essenceous food"}
                              </span>
                            </h1>
                            <div className="text">
                              Come with family & feel the joy of mouthwatering
                              food
                            </div>
                            {/*<div className="links-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                                            <div className="link">
                                                                <Link to="#" className="theme-btn btn-style-two clearfix">
                                                                    <span className="btn-wrap">
                                                                        <span className="text-one">view our menu</span>
                                                                        <span className="text-two">view our menu</span>
                                                                    </span>
                                                                </Link>
                                                            </div>
                                                        </div>*/}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </div>
          <div className="swiper-button-prev">
            <span className="fal fa-angle-left"></span>
          </div>
          <div className="swiper-button-next">
            <span className="fal fa-angle-right"></span>
          </div>
        </div>
      </div>
      <div className="book-btn">
        <Link to="/contact" className="theme-btn">
          <span className="icon">
            <img src={bookicon1} alt="" title="" />
          </span>
          <span className="txt">book a table</span>
        </Link>
      </div>
    </Swiper>
  );
}

export default Banner;
